html[data-scheme='magnit'] {
  --scheme-version: 1.18;
  --brand-primary: #e30613;
  --brand-primary-light: #ff3448;
  --brand-secondary: #cd0375;
  --brand-secondary-light: #de1486;
  --yellow: #f5c714;
  --green: #6bb33e;
  --dark-red: #bf3037;
  --red: #ff4b54;
  --light-violet: #b6ffb8;
  --black: #000000;
  --gray90: #1e1e1f;
  --primary-dark: #2f2f30;
  --gray80: #7a7a7d;
  --gray50: #a3a3a6;
  --gray30: #e1e1e6;
  --gray20: #f0f0f2;
  --white: #ffffff;
  --brand-gradient-start-color: #37e139;
  --brand-gradient-end-color: #68e190;
  --avatar-gradient-angle: 45;
  --avatar-gradient-start-color: #37e139;
  --avatar-gradient-end-color: #68e190;
  --stable-primary-bg: #e30613;
  --stable-default-bg: #000000;
  --stable-secondary-bg: #2f2f30;
  --stable-accent-bg: #e30613;
  --stable-white-bg: #ffffff;
  --stable-text-default: #ffffff;
  --stable-text-secondary: #e1e1e6;
  --stable-icon-default: #ffffff;
  --stable-icon-secondary: #e1e1e6;
  --stable-blocked: #a3a3a6;
  --stable-accent-light: #65fb68;
  --stable-like: #ff4b54;
  --stable-radius-xs: 4px;
  --stable-radius-s: 10px;
  --stable-radius-m: 16px;
  --stable-border-width: 1px;
  --stable-border-input-focus: 1.5px;
  --stable-divider-width: 0.5px;
  --stable-player-seek-default: #7a7a7d;
  --stable-player-seek-loaded: #e30613;
  --fonts-brand-header: var(--russo-one);
  --fonts-brand-header-weight: 400;
  --fonts-text: var(--roboto);
}

html[data-scheme='magnit'][data-theme='light'] {
  --background-default: #ffffff;
  --background-overlay: #ffffff;
  --background-secondary: #f0f0f2;
  --background-inversion: #2f2f30;
  --background-accent: #b6ffb8;
  --support-error: #bf3037;
  --support-success: #6bb33e;
  --support-warning: #f5c714;
  --icon-default: #2f2f30;
  --icon-secondary: #7a7a7d;
  --icon-inversion: #ffffff;
  --icon-accent1: #e30613;
  --icon-accent2: #37e139;
  --divider-default: #f0f0f2;
  --button-bg-primary: #e30613;
  --button-bg-secondary: #f0f0f2;
  --button-bg-ghost: #ffffff;
  --button-bg-disabled: #f0f0f2;
  --button-bg-inverse: #e30613;
  --button-label-primary: #ffffff;
  --button-label-secondary: #2f2f30;
  --button-label-ghost: #e30613;
  --button-label-disabled: #7a7a7d;
  --button-icon-primary: #ffffff;
  --button-icon-secondary: #2f2f30;
  --button-icon-ghost: #e30613;
  --button-icon-disabled: #7a7a7d;
  --button-icon-phone: #e30613;
  --button-icon-email: #cd0375;
  --text-default: #2f2f30;
  --text-secondary: #7a7a7d;
  --text-default-inversion: #ffffff;
  --text-secondary-inversion: #e1e1e6;
  --text-accent1: #e30613;
  --text-accent2: #37e139;
  --text-accent3: #6bb33e;
  --tab-indicator-active: #e30613;
  --tab-label-active: #2f2f30;
  --tab-label-default: #7a7a7d;
  --bottom-nav-icon-default: #7a7a7d;
  --bottom-nav-icon-active: #e30613;
  --bottom-nav-badge: #bf3037;
  --chips-border-default: #e1e1e6;
  --chips-border-active: #37e139;
  --chips-label-default: #2f2f30;
  --chips-label-active: #2f2f30;
  --chips-bg-default: #ffffff;
  --chips-bg-active: #f0f0f2;
  --border-primary: #ffffff;
  --border-secondary: #e1e1e6;
  --border-third: #2f2f30;
  --input-bg-default: #f0f0f2;
  --input-bg-focused: #ffffff;
  --input-bg-filled: #f0f0f2;
  --input-bg-error: #ffffff;
  --input-border-default: #f0f0f2;
  --input-border-focused: #a3a3a6;
  --input-border-filled: #f0f0f2;
  --input-border-error: #bf3037;
  --font-family-brand-header: var(--russo-one);
}

html[data-scheme='magnit'][data-theme='dark'] {
  --background-default: #000000;
  --background-overlay: #1e1e1f;
  --background-secondary: #2f2f30;
  --background-inversion: #ffffff;
  --background-accent: #2f2f30;
  --text-default: #f0f0f2;
  --text-secondary: #a3a3a6;
  --text-default-inversion: #2f2f30;
  --text-secondary-inversion: #7a7a7d;
  --text-accent1: #ff3448;
  --text-accent2: #65fb68;
  --text-accent3: #6bb33e;
  --icon-default: #f0f0f2;
  --icon-secondary: #a3a3a6;
  --icon-inversion: #2f2f30;
  --icon-accent1: #ff3448;
  --icon-accent2: #65fb68;
  --divider-default: #7a7a7d;
  --button-bg-primary: #ff3448;
  --button-bg-secondary: #2f2f30;
  --button-bg-ghost: #000000;
  --button-bg-disabled: #2f2f30;
  --button-bg-inverse: #e30613;
  --button-label-primary: #ffffff;
  --button-label-secondary: #ffffff;
  --button-label-ghost: #ff3448;
  --button-label-disabled: #7a7a7d;
  --button-icon-primary: #ffffff;
  --button-icon-secondary: #ffffff;
  --button-icon-ghost: #ff3f49;
  --button-icon-disabled: #7a7a7d;
  --button-icon-phone: #ff3448;
  --button-icon-email: #de1486;
  --tab-indicator-active: #ff3448;
  --tab-label-active: #f0f0f2;
  --tab-label-default: #a3a3a6;
  --bottom-nav-icon-default: #a3a3a6;
  --bottom-nav-icon-active: #ff3448;
  --bottom-nav-badge: #bf3037;
  --chips-bg-default: #000000;
  --chips-bg-active: #f0f0f2;
  --chips-label-default: #f0f0f2;
  --chips-label-active: #2f2f30;
  --chips-border-default: #7a7a7d;
  --chips-border-active: #a160ff;
  --support-error: #ff4b54;
  --support-success: #6bb33e;
  --support-warning: #f5c714;
  --border-primary: #7a7a7d;
  --border-secondary: #7a7a7d;
  --border-third: #7a7a7d;
  --input-bg-default: #2f2f30;
  --input-bg-focused: #000000;
  --input-bg-filled: #2f2f30;
  --input-bg-error: #000000;
  --input-border-default: #7a7a7d;
  --input-border-focused: #e1e1e6;
  --input-border-filled: #7a7a7d;
  --input-border-error: #ff4b54;
}
