.rotating-plane {
  width: 40px;
  height: 40px;
  position: relative;
  background-color: var(--spinner-color);

  -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
  animation: sk-rotateplane 1.2s infinite ease-in-out;

  @-webkit-keyframes sk-rotateplane {
    0% {
      -webkit-transform: perspective(120px)
    }
    50% {
      -webkit-transform: perspective(120px) rotateY(180deg)
    }
    100% {
      -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg)
    }
  }

  @keyframes sk-rotateplane {
    0% {
      transform: perspective(120px) rotateX(0deg) rotateY(0deg);
      -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg)
    }
    50% {
      transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
      -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg)
    }
    100% {
      transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
      -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
  }
}

.chasing-circle {
  width: 40px;
  height: 40px;
  position: relative;
  animation: sk-chase 2.5s infinite linear both;

  .sk-chase-dot {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    animation: sk-chase-dot 2.0s infinite ease-in-out both;
  }

  .sk-chase-dot:before {
    content: '';
    display: block;
    width: 25%;
    height: 25%;
    background-color: var(--spinner-color);
    border-radius: 100%;
    animation: sk-chase-dot-before 2.0s infinite ease-in-out both;
  }

  .sk-chase-dot:nth-child(1) {
    animation-delay: -1.1s;
  }

  .sk-chase-dot:nth-child(2) {
    animation-delay: -1.0s;
  }

  .sk-chase-dot:nth-child(3) {
    animation-delay: -0.9s;
  }

  .sk-chase-dot:nth-child(4) {
    animation-delay: -0.8s;
  }

  .sk-chase-dot:nth-child(5) {
    animation-delay: -0.7s;
  }

  .sk-chase-dot:nth-child(6) {
    animation-delay: -0.6s;
  }

  .sk-chase-dot:nth-child(1):before {
    animation-delay: -1.1s;
  }

  .sk-chase-dot:nth-child(2):before {
    animation-delay: -1.0s;
  }

  .sk-chase-dot:nth-child(3):before {
    animation-delay: -0.9s;
  }

  .sk-chase-dot:nth-child(4):before {
    animation-delay: -0.8s;
  }

  .sk-chase-dot:nth-child(5):before {
    animation-delay: -0.7s;
  }

  .sk-chase-dot:nth-child(6):before {
    animation-delay: -0.6s;
  }

  @keyframes sk-chase {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes sk-chase-dot {
    80%, 100% {
      transform: rotate(360deg);
    }
  }

  @keyframes sk-chase-dot-before {
    50% {
      transform: scale(0.4);
    }
    100%, 0% {
      transform: scale(1.0);
    }
  }
}

.double-bounce {
  width: 40px;
  height: 40px;
  position: relative;

  .double-bounce1, .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: var(--spinner-color);
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;

    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out;
  }

  .double-bounce2 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
  }

  @-webkit-keyframes sk-bounce {
    0%, 100% {
      -webkit-transform: scale(0.0)
    }
    50% {
      -webkit-transform: scale(1.0)
    }
  }

  @keyframes sk-bounce {
    0%, 100% {
      transform: scale(0.0);
      -webkit-transform: scale(0.0);
    }
    50% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
  }
}

.wave {
  width: 50px;
  height: 40px;
  position: relative;
  text-align: center;
  font-size: 10px;

  .rect1, .rect2, .rect3, .rect4, .rect5 {
    background-color: var(--spinner-color);
    height: 100%;
    width: 6px;
    display: inline-block;
    margin: 0 3px 0 0;
    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
  }

  .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }

  .rect3 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
  }

  .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }

  .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }

  @-webkit-keyframes sk-stretchdelay {
    0%, 40%, 100% {
      -webkit-transform: scaleY(0.4)
    }
    20% {
      -webkit-transform: scaleY(1.0)
    }
  }

  @keyframes sk-stretchdelay {
    0%, 40%, 100% {
      transform: scaleY(0.4);
      -webkit-transform: scaleY(0.4);
    }
    20% {
      transform: scaleY(1.0);
      -webkit-transform: scaleY(1.0);
    }
  }
}

.wandering-cubes {
  width: 40px;
  height: 40px;
  position: relative;

  .cube1, .cube2 {
    background-color: var(--spinner-color);
    width: 15px;
    height: 15px;
    position: absolute;
    top: 0;
    left: 0;

    -webkit-animation: sk-cubemove 1.8s infinite ease-in-out;
    animation: sk-cubemove 1.8s infinite ease-in-out;
  }

  .cube2 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }

  @-webkit-keyframes sk-cubemove {
    25% {
      -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5)
    }
    50% {
      -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg)
    }
    75% {
      -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5)
    }
    100% {
      -webkit-transform: rotate(-360deg)
    }
  }

  @keyframes sk-cubemove {
    25% {
      transform: translateX(42px) rotate(-90deg) scale(0.5);
      -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
    }
    50% {
      transform: translateX(42px) translateY(42px) rotate(-179deg);
      -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
    }
    50.1% {
      transform: translateX(42px) translateY(42px) rotate(-180deg);
      -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
    }
    75% {
      transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
      -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
    }
    100% {
      transform: rotate(-360deg);
      -webkit-transform: rotate(-360deg);
    }
  }
}

.pulse {
  width: 40px;
  height: 40px;
  background-color: var(--spinner-color);

  border-radius: 100%;
  -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
  animation: sk-scaleout 1.0s infinite ease-in-out;

  @-webkit-keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0)
    }
    100% {
      -webkit-transform: scale(1.0);
      opacity: 0;
    }
  }

  @keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
      opacity: 0;
    }
  }
}

.chasing-dots {
  width: 40px;
  height: 40px;
  position: relative;
  text-align: center;

  -webkit-animation: sk-rotate 2.0s infinite linear;
  animation: sk-rotate 2.0s infinite linear;

  .dot1, .dot2 {
    width: 60%;
    height: 60%;
    display: inline-block;
    position: absolute;
    top: 0;
    background-color: var(--spinner-color);
    border-radius: 100%;

    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out;
  }

  .dot2 {
    top: auto;
    bottom: 0;
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
  }

  @-webkit-keyframes sk-rotate {
    100% {
      -webkit-transform: rotate(360deg)
    }
  }
  @keyframes sk-rotate {
    100% {
      transform: rotate(360deg);
      -webkit-transform: rotate(360deg)
    }
  }

  @-webkit-keyframes sk-bounce {
    0%, 100% {
      -webkit-transform: scale(0.0)
    }
    50% {
      -webkit-transform: scale(1.0)
    }
  }

  @keyframes sk-bounce {
    0%, 100% {
      transform: scale(0.0);
      -webkit-transform: scale(0.0);
    }
    50% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
  }
}

.three-bounce {
  width: 70px;
  text-align: center;

  .bounce1, .bounce2, .bounce3 {
    width: 18px;
    height: 18px;
    background-color: var(--spinner-color);

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  @-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% {
      -webkit-transform: scale(0)
    }
    40% {
      -webkit-transform: scale(1.0)
    }
  }

  @keyframes sk-bouncedelay {
    0%, 80%, 100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
    }
  }
}

.circle {
  width: 40px;
  height: 40px;
  position: relative;

  .circle1, .circle2, .circle3, .circle4,
  .circle5, .circle6, .circle7, .circle8,
  .circle9, .circle10, .circle11, .circle12 {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    &:before {
      content: '';
      display: block;
      margin: 0 auto;
      width: 15%;
      height: 15%;
      background-color: var(--spinner-color);
      border-radius: 100%;
      -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
      animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
    }
  }

  .circle2 {
    -webkit-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    transform: rotate(30deg);

    &:before {
      -webkit-animation-delay: -1.1s;
      animation-delay: -1.1s;
    }
  }

  .circle3 {
    -webkit-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    transform: rotate(60deg);

    &:before {
      -webkit-animation-delay: -1s;
      animation-delay: -1s;
    }
  }

  .circle4 {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);

    &:before {
      -webkit-animation-delay: -0.9s;
      animation-delay: -0.9s;
    }
  }

  .circle5 {
    -webkit-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    transform: rotate(120deg);

    &:before {
      -webkit-animation-delay: -0.8s;
      animation-delay: -0.8s;
    }
  }

  .circle6 {
    -webkit-transform: rotate(150deg);
    -ms-transform: rotate(150deg);
    transform: rotate(150deg);

    &:before {
      -webkit-animation-delay: -0.7s;
      animation-delay: -0.7s;
    }
  }

  .circle7 {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);

    &:before {
      -webkit-animation-delay: -0.6s;
      animation-delay: -0.6s;
    }
  }

  .circle8 {
    -webkit-transform: rotate(210deg);
    -ms-transform: rotate(210deg);
    transform: rotate(210deg);

    &:before {
      -webkit-animation-delay: -0.5s;
      animation-delay: -0.5s;
    }
  }

  .circle9 {
    -webkit-transform: rotate(240deg);
    -ms-transform: rotate(240deg);
    transform: rotate(240deg);

    &:before {
      -webkit-animation-delay: -0.4s;
      animation-delay: -0.4s;
    }
  }

  .circle10 {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);

    &:before {
      -webkit-animation-delay: -0.3s;
      animation-delay: -0.3s;
    }
  }

  .circle11 {
    -webkit-transform: rotate(300deg);
    -ms-transform: rotate(300deg);
    transform: rotate(300deg);

    &:before {
      -webkit-animation-delay: -0.2s;
      animation-delay: -0.2s;
    }
  }

  .circle12 {
    -webkit-transform: rotate(330deg);
    -ms-transform: rotate(330deg);
    transform: rotate(330deg);

    &:before {
      -webkit-animation-delay: -0.1s;
      animation-delay: -0.1s;
    }
  }

  @-webkit-keyframes sk-circleBounceDelay {
    0%, 80%, 100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  @keyframes sk-circleBounceDelay {
    0%, 80%, 100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
}

.cube-grid {
  width: 42px;
  height: 42px;
  position: relative;

  .cube1, .cube2, .cube3, .cube4, .cube5, .cube6, .cube7, .cube8, .cube9 {
    width: 33%;
    height: 33%;
    background-color: var(--spinner-color);
    float: left;
    -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
    animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  }

  .cube1 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }

  .cube2 {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }

  .cube3 {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
  }

  .cube4 {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
  }

  .cube5 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }

  .cube6 {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }

  .cube7 {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }

  .cube8 {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
  }

  .cube9 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }

  @-webkit-keyframes sk-cubeGridScaleDelay {
    0%, 70%, 100% {
      -webkit-transform: scale3D(1, 1, 1);
      transform: scale3D(1, 1, 1);
    }
    35% {
      -webkit-transform: scale3D(0, 0, 1);
      transform: scale3D(0, 0, 1);
    }
  }

  @keyframes sk-cubeGridScaleDelay {
    0%, 70%, 100% {
      -webkit-transform: scale3D(1, 1, 1);
      transform: scale3D(1, 1, 1);
    }
    35% {
      -webkit-transform: scale3D(0, 0, 1);
      transform: scale3D(0, 0, 1);
    }
  }
}

.fading-circle {
  width: 40px;
  height: 40px;
  position: relative;
  display: inline-block;

  .circle1, .circle2, .circle3, .circle4,
  .circle5, .circle6, .circle7, .circle8,
  .circle9, .circle10, .circle11, .circle12 {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    &:before {
      content: '';
      display: block;
      margin: 0 auto;
      width: 15%;
      height: 15%;
      background-color: var(--spinner-color);
      border-radius: 100%;
      -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
      animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
    }
  }

  .circle2 {
    -webkit-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    transform: rotate(30deg);

    &:before {
      -webkit-animation-delay: -1.1s;
      animation-delay: -1.1s;
    }
  }

  .circle3 {
    -webkit-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    transform: rotate(60deg);

    &:before {
      -webkit-animation-delay: -1s;
      animation-delay: -1s;
    }
  }

  .circle4 {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);

    &:before {
      -webkit-animation-delay: -0.9s;
      animation-delay: -0.9s;
    }
  }

  .circle5 {
    -webkit-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    transform: rotate(120deg);

    &:before {
      -webkit-animation-delay: -0.8s;
      animation-delay: -0.8s;
    }
  }

  .circle6 {
    -webkit-transform: rotate(150deg);
    -ms-transform: rotate(150deg);
    transform: rotate(150deg);

    &:before {
      -webkit-animation-delay: -0.7s;
      animation-delay: -0.7s;
    }
  }

  .circle7 {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);

    &:before {
      -webkit-animation-delay: -0.6s;
      animation-delay: -0.6s;
    }
  }

  .circle8 {
    -webkit-transform: rotate(210deg);
    -ms-transform: rotate(210deg);
    transform: rotate(210deg);

    &:before {
      -webkit-animation-delay: -0.5s;
      animation-delay: -0.5s;
    }
  }

  .circle9 {
    -webkit-transform: rotate(240deg);
    -ms-transform: rotate(240deg);
    transform: rotate(240deg);

    &:before {
      -webkit-animation-delay: -0.4s;
      animation-delay: -0.4s;
    }
  }

  .circle10 {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);

    &:before {
      -webkit-animation-delay: -0.3s;
      animation-delay: -0.3s;
    }
  }

  .circle11 {
    -webkit-transform: rotate(300deg);
    -ms-transform: rotate(300deg);
    transform: rotate(300deg);

    &:before {
      -webkit-animation-delay: -0.2s;
      animation-delay: -0.2s;
    }
  }

  .circle12 {
    -webkit-transform: rotate(330deg);
    -ms-transform: rotate(330deg);
    transform: rotate(330deg);

    &:before {
      -webkit-animation-delay: -0.1s;
      animation-delay: -0.1s;
    }
  }

  @-webkit-keyframes sk-circleFadeDelay {
    0%, 39%, 100% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
  }

  @keyframes sk-circleFadeDelay {
    0%, 39%, 100% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
  }
}

.folding-cube {
  width: 40px;
  height: 40px;
  position: relative;
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);

  .cube1, .cube2, .cube3, .cube4 {
    float: left;
    width: 50%;
    height: 50%;
    position: relative;
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--spinner-color);
      -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
      animation: sk-foldCubeAngle 2.4s infinite linear both;
      -webkit-transform-origin: 100% 100%;
      -ms-transform-origin: 100% 100%;
      transform-origin: 100% 100%;
    }
  }

  .cube2 {
    -webkit-transform: scale(1.1) rotateZ(90deg);
    transform: scale(1.1) rotateZ(90deg);

    &:before {
      -webkit-animation-delay: 0.3s;
      animation-delay: 0.3s;
    }
  }

  .cube3 {
    -webkit-transform: scale(1.1) rotateZ(180deg);
    transform: scale(1.1) rotateZ(180deg);

    &:before {
      -webkit-animation-delay: 0.6s;
      animation-delay: 0.6s;
    }
  }

  .cube4 {
    -webkit-transform: scale(1.1) rotateZ(270deg);
    transform: scale(1.1) rotateZ(270deg);

    &:before {
      -webkit-animation-delay: 0.9s;
      animation-delay: 0.9s;
    }
  }

  @-webkit-keyframes sk-foldCubeAngle {
    0%, 10% {
      -webkit-transform: perspective(140px) rotateX(-180deg);
      transform: perspective(140px) rotateX(-180deg);
      opacity: 0;
    }
    25%, 75% {
      -webkit-transform: perspective(140px) rotateX(0deg);
      transform: perspective(140px) rotateX(0deg);
      opacity: 1;
    }
    90%, 100% {
      -webkit-transform: perspective(140px) rotateY(180deg);
      transform: perspective(140px) rotateY(180deg);
      opacity: 0;
    }
  }

  @keyframes sk-foldCubeAngle {
    0%, 10% {
      -webkit-transform: perspective(140px) rotateX(-180deg);
      transform: perspective(140px) rotateX(-180deg);
      opacity: 0;
    }
    25%, 75% {
      -webkit-transform: perspective(140px) rotateX(0deg);
      transform: perspective(140px) rotateX(0deg);
      opacity: 1;
    }
    90%, 100% {
      -webkit-transform: perspective(140px) rotateY(180deg);
      transform: perspective(140px) rotateY(180deg);
      opacity: 0;
    }
  }
}

.rotating-semicircle {
  width: 40px;
  height: 40px;
  position: relative;
  animation: rotate-circle 1.2s infinite linear;

  @-webkit-keyframes rotate-circle {
    0% {
      -webkit-transform: rotate(0deg)
    }
    100% {
      -webkit-transform: rotate(360deg)
    }
  }

  @keyframes rotate-circle {
    0% {
      transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
    }
  }

  svg {
    position: relative;
    width: 100%;
    height: 100%;

    circle {
      width: 100%;
      height: 100%;
      fill: none;
      stroke-width: 10%;
      stroke-dasharray: 125;
      stroke-dashoffset: 125 - (125 * 80) / 100;
      stroke: var(--spinner-color);
      stroke-linecap: round;
    }
  }
}

.ring {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;

  @keyframes ring-animation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  & > div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 40px;
    height: 40px;
    border: 3px solid white; // TODO
    border-radius: 50%;
    animation: ring-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: white transparent transparent transparent;
  }

  .circle1 {
    animation-delay: -0.45s;
  }
  .circle2 {
    animation-delay: -0.3s;
  }
  .circle3 {
    animation-delay: -0.15s;
  }
}

.dual-ring {
  display: inline-block;
  width: 40px;
  height: 40px;

  @keyframes dual-ring-animation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  &:after {
    content: " ";
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 6px solid var(--spinner-color);
    border-color: var(--spinner-color) transparent var(--spinner-color) transparent;
    animation: dual-ring-animation 1.2s linear infinite;
  }
}
