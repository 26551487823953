@import '../../../scss/typography';

.container {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 11;

  pointer-events: none;
  cursor: pointer;

  &[data-overlay='true'] {
    background: rgba(0, 0, 0, 0.35);
  }
}

.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 150px;
  background: var(--white);
  box-shadow: 0 10px 18px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  padding: 24px 16px;
  z-index: 30;
  pointer-events: auto;

  .close {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 16px;
    top: 24px;

    svg {
      fill: var(--icon-secondary);
    }
  }

  .title {
    @include title-2-medium;
    color: var(--text-default);
    margin-bottom: 12px;
  }

  .text {
    @include headline-regular;
    color: var(--text-default);
  }

  .btns {
    @include headline-regular;
    display: flex;
    margin-top: 24px;
    color: var(--text-secondary);
    justify-content: flex-end;
    margin-bottom: -16px;

    .btn:last-child {
      color: var(--text-accent3);
    }
  }
}
