html[data-scheme='rosseti'] {
  --scheme-version: 1.0;
  --brand-primary: #005A9B;
  --brand-primary-light: #0076CC;
  --brand-secondary: #5FD0DF;
  --brand-secondary-light: #34BACC;
  --yellow: #F5C714;
  --orange: #FF6D33;
  --green: #6BB33E;
  --dark-red: #BF3037;
  --red: #FF4B54;
  --light-violet: #E1F6F9;
  --black: #000000;
  --gray90: #1E1E1F;
  --primary-dark: #2F2F30;
  --gray80: #7A7A7D;
  --gray50: #A3A3A6;
  --gray30: #E1E1E6;
  --gray20: #F0F0F2;
  --white: #FFFFFF;
  --brand-gradient-angle: 45;
  --brand-gradient-start-color: #005A9B;
  --brand-gradient-end-color: #0093FF;
  --avatar-gradient-angle: 45;
  --avatar-gradient-start-color: #005A9B;
  --avatar-gradient-end-color: #0093FF;
  --stable-primary-bg: #0076CC;
  --stable-accent-bg: #005A9B;
  --stable-default-bg: #000000;
  --stable-secondary-bg: #2F2F30;
  --stable-white-bg: #FFFFFF;
  --stable-text-default: #FFFFFF;
  --stable-text-secondary: #E1E1E6;
  --stable-icon-default: #FFFFFF;
  --stable-icon-secondary: #E1E1E6;
  --stable-blocked: #A3A3A6;
  --stable-accent-light: #34BACC;
  --stable-like: #FF4B54;
  --stable-radius-xs: 4px;
  --stable-radius-s: 10px;
  --stable-radius-m: 16px;
  --stable-border-width: 1px;
  --stable-border-input-focus: 1.5px;
  --stable-divider-width: 0.5px;
  --stable-player-seek-default: #7A7A7D;
  --stable-player-seek-loaded: #005A9B;
  --fonts-brand-header: var(--pf-din-text-cond-pro);
  --fonts-brand-header-weight: 700;
  --fonts-text: var(--roboto);
}

html[data-scheme='rosseti'][data-theme='light'] {
  --background-default: #FFFFFF;
  --background-overlay: #FFFFFF;
  --background-secondary: #F0F0F2;
  --background-inversion: #2F2F30;
  --background-accent: #E1F6F9;
    --support-error: #BF3037;
  --support-success: #6BB33E;
  --support-warning: #F5C714;
  --icon-default: #2F2F30;
  --icon-secondary: #7A7A7D;
  --icon-inversion: #FFFFFF;
  --icon-accent1: #005A9B;
  --icon-accent2: #5FD0DF;
  --divider-default: #F0F0F2;
  --button-bg-primary: #005A9B;
  --button-bg-secondary: #F0F0F2;
  --button-bg-ghost: #FFFFFF;
  --button-bg-disabled: #F0F0F2;
  --button-bg-inverse: #0076CC;
  --button-label-primary: #FFFFFF;
  --button-label-secondary: #2F2F30;
  --button-label-ghost: #005A9B;
  --button-label-disabled: #7A7A7D;
  --button-icon-primary: #FFFFFF;
  --button-icon-secondary: #2F2F30;
  --button-icon-ghost: #005A9B;
  --button-icon-disabled: #7A7A7D;
  --button-icon-phone: #005A9B;
  --button-icon-email: #5FD0DF;
  --text-default: #2F2F30;
  --text-secondary: #7A7A7D;
  --text-default-inversion: #FFFFFF;
  --text-secondary-inversion: #E1E1E6;
  --text-accent1: #005A9B;
  --text-accent2: #5FD0DF;
  --text-accent3: #6BB33E;
  --tab-indicator-active: #005A9B;
  --tab-label-active: #2F2F30;
  --tab-label-default: #7A7A7D;
  --bottom-nav-icon-default: #7A7A7D;
  --bottom-nav-icon-active: #005A9B;
  --bottom-nav-badge: #BF3037;
  --chips-border-default: #E1E1E6;
  --chips-border-active: #005A9B;
  --chips-label-default: #2F2F30;
  --chips-label-active: #2F2F30;
  --chips-bg-default: #FFFFFF;
  --chips-bg-active: #F0F0F2;
  --border-primary: #FFFFFF;
  --border-secondary: #E1E1E6;
  --border-third: #2F2F30;
  --input-bg-default: #F0F0F2;
  --input-bg-focused: #FFFFFF;
  --input-bg-filled: #F0F0F2;
  --input-bg-error: #FFFFFF;
  --input-border-default: #F0F0F2;
  --input-border-focused: #A3A3A6;
  --input-border-filled: #F0F0F2;
  --input-border-error: #BF3037;
}

html[data-scheme='rosseti'][data-theme='dark'] {
  --background-default: #000000;
  --background-overlay: #1E1E1F;
  --background-secondary: #2F2F30;
  --background-inversion: #FFFFFF;
  --background-accent: #2F2F30;
    --text-default: #F0F0F2;
  --text-secondary: #A3A3A6;
  --text-default-inversion: #2F2F30;
  --text-secondary-inversion: #7A7A7D;
  --text-accent1: #0076CC;
  --text-accent2: #34BACC;
  --text-accent3: #6BB33E;
  --icon-default: #F0F0F2;
  --icon-secondary: #A3A3A6;
  --icon-inversion: #2F2F30;
  --icon-accent1: #0076CC;
  --icon-accent2: #34BACC;
  --divider-default: #7A7A7D;
  --button-bg-primary: #0076CC;
  --button-bg-secondary: #2F2F30;
  --button-bg-ghost: #000000;
  --button-bg-disabled: #2F2F30;
  --button-bg-inverse: #005A9B;
  --button-label-primary: #FFFFFF;
  --button-label-secondary: #FFFFFF;
  --button-label-ghost: #0076CC;
  --button-label-disabled: #7A7A7D;
  --button-icon-primary: #FFFFFF;
  --button-icon-secondary: #FFFFFF;
  --button-icon-ghost: #0076CC;
  --button-icon-disabled: #7A7A7D;
  --button-icon-phone: #0076CC;
  --button-icon-email: #34BACC;
  --tab-indicator-active: #0076CC;
  --tab-label-active: #F0F0F2;
  --tab-label-default: #A3A3A6;
  --bottom-nav-icon-default: #A3A3A6;
  --bottom-nav-icon-active: #0076CC;
  --bottom-nav-badge: #BF3037;
  --chips-bg-default: #000000;
  --chips-bg-active: #F0F0F2;
  --chips-label-default: #F0F0F2;
  --chips-label-active: #2F2F30;
  --chips-border-default: #7A7A7D;
  --chips-border-active: #0076CC;
  --support-error: #FF4B54;
  --support-success: #6BB33E;
  --support-warning: #F5C714;
  --border-primary: #7A7A7D;
  --border-secondary: #7A7A7D;
  --border-third: #7A7A7D;
  --input-bg-default: #2F2F30;
  --input-bg-focused: #000000;
  --input-bg-filled: #2F2F30;
  --input-bg-error: #000000;
  --input-border-default: #7A7A7D;
  --input-border-focused: #E1E1E6;
  --input-border-filled: #7A7A7D;
  --input-border-error: #FF4B54;
}
