html[data-scheme='highland'] {
  --scheme-version: 1.0;
  --brand-primary: #867252;
  --brand-primary-light: #C4A05D;
  --brand-secondary: #1D345A;
  --brand-secondary-light: #8297BC;
  --yellow: #F5C714;
  --orange: #FF6D33;
  --green: #48AD83;
  --dark-red: #BF3037;
  --red: #FF4B54;
  --light-violet: #E8F0FE;
  --dark-violet: #323F56;
  --black: #000000;
  --gray90: #1E1E1F;
  --primary-dark: #2F2F30;
  --gray80: #7A7A7D;
  --gray50: #A3A3A6;
  --gray30: #E1E1E6;
  --gray20: #F0F0F2;
  --white: #FFFFFF;
  --brand-gradient-angle: 45;
  --brand-gradient-start-color: #867252;
  --brand-gradient-end-color: #C4A05D;
  --avatar-gradient-angle: 45;
  --avatar-gradient-start-color: #867252;
  --avatar-gradient-end-color: #C4A05D;
  --stable-primary-bg: #C4A05D;
  --stable-accent-bg: #867252;
  --stable-default-bg: #000000;
  --stable-secondary-bg: #2F2F30;
  --stable-white-bg: #FFFFFF;
  --stable-text-default: #FFFFFF;
  --stable-text-secondary: #E1E1E6;
  --stable-icon-default: #FFFFFF;
  --stable-icon-secondary: #E1E1E6;
  --stable-blocked: #A3A3A6;
  --stable-accent-light: #8297BC;
  --stable-like: #FF4B54;
  --stable-radius-xs: 4px;
  --stable-radius-s: 10px;
  --stable-radius-m: 16px;
  --stable-border-width: 1px;
  --stable-border-input-focus: 1.5px;
  --stable-divider-width: 0.5px;
  --stable-player-seek-default: #7A7A7D;
  --stable-player-seek-loaded: #1D345A;
  --fonts-brand-header: var(--montserrat-bold);
  --fonts-brand-header-weight: 700;
  --fonts-text: var(--roboto);
}

html[data-scheme='highland'][data-theme='light'] {
  --background-default: #FFFFFF;
  --background-overlay: #FFFFFF;
  --background-secondary: #F0F0F2;
  --background-inversion: #2F2F30;
  --background-accent: #E8F0FE;
  --support-error: #BF3037;
  --support-success: #48AD83;
  --support-warning: #F5C714;
  --icon-default: #2F2F30;
  --icon-secondary: #7A7A7D;
  --icon-inversion: #FFFFFF;
  --icon-accent1: #867252;
  --icon-accent2: #1D345A;
  --divider-default: #F0F0F2;
  --button-bg-primary: #867252;
  --button-bg-secondary: #F0F0F2;
  --button-bg-ghost: #FFFFFF;
  --button-bg-disabled: #F0F0F2;
  --button-bg-inverse: #C4A05D;
  --button-label-primary: #FFFFFF;
  --button-label-secondary: #2F2F30;
  --button-label-ghost: #867252;
  --button-label-disabled: #7A7A7D;
  --button-icon-primary: #FFFFFF;
  --button-icon-secondary: #2F2F30;
  --button-icon-ghost: #867252;
  --button-icon-disabled: #7A7A7D;
  --button-icon-phone: #867252;
  --button-icon-email: #1D345A;
  --text-default: #2F2F30;
  --text-secondary: #7A7A7D;
  --text-default-inversion: #FFFFFF;
  --text-secondary-inversion: #E1E1E6;
  --text-accent1: #867252;
  --text-accent2: #1D345A;
  --text-accent3: #48AD83;
  --tab-indicator-active: #867252;
  --tab-label-active: #2F2F30;
  --tab-label-default: #7A7A7D;
  --bottom-nav-icon-default: #7A7A7D;
  --bottom-nav-icon-active: #1D345A;
  --bottom-nav-badge: #BF3037;
  --chips-border-default: #E1E1E6;
  --chips-border-active: #867252;
  --chips-label-default: #2F2F30;
  --chips-label-active: #2F2F30;
  --chips-bg-default: #FFFFFF;
  --chips-bg-active: #F0F0F2;
  --border-primary: #FFFFFF;
  --border-secondary: #E1E1E6;
  --border-third: #2F2F30;
  --input-bg-default: #F0F0F2;
  --input-bg-focused: #FFFFFF;
  --input-bg-filled: #F0F0F2;
  --input-bg-error: #FFFFFF;
  --input-border-default: #F0F0F2;
  --input-border-focused: #A3A3A6;
  --input-border-filled: #F0F0F2;
  --input-border-error: #BF3037;
}

html[data-scheme='highland'][data-theme='dark'] {
  --background-default: #000000;
  --background-overlay: #1E1E1F;
  --background-secondary: #2F2F30;
  --background-inversion: #FFFFFF;
  --background-accent: #323F56;
  --text-default: #F0F0F2;
  --text-secondary: #A3A3A6;
  --text-default-inversion: #2F2F30;
  --text-secondary-inversion: #7A7A7D;
  --text-accent1: #C4A05D;
  --text-accent2: #8297BC;
  --text-accent3: #48AD83;
  --icon-default: #F0F0F2;
  --icon-secondary: #A3A3A6;
  --icon-inversion: #2F2F30;
  --icon-accent1: #C4A05D;
  --icon-accent2: #8297BC;
  --divider-default: #7A7A7D;
  --button-bg-primary: #C4A05D;
  --button-bg-secondary: #2F2F30;
  --button-bg-ghost: #000000;
  --button-bg-disabled: #2F2F30;
  --button-bg-inverse: #867252;
  --button-label-primary: #FFFFFF;
  --button-label-secondary: #FFFFFF;
  --button-label-ghost: #C4A05D;
  --button-label-disabled: #7A7A7D;
  --button-icon-primary: #FFFFFF;
  --button-icon-secondary: #FFFFFF;
  --button-icon-ghost: #C4A05D;
  --button-icon-disabled: #7A7A7D;
  --button-icon-phone: #C4A05D;
  --button-icon-email: #8297BC;
  --tab-indicator-active: #C4A05D;
  --tab-label-active: #F0F0F2;
  --tab-label-default: #A3A3A6;
  --bottom-nav-icon-default: #A3A3A6;
  --bottom-nav-icon-active: #8297BC;
  --bottom-nav-badge: #BF3037;
  --chips-bg-default: #000000;
  --chips-bg-active: #F0F0F2;
  --chips-label-default: #F0F0F2;
  --chips-label-active: #2F2F30;
  --chips-border-default: #7A7A7D;
  --chips-border-active: #C4A05D;
  --support-error: #FF4B54;
  --support-success: #48AD83;
  --support-warning: #F5C714;
  --border-primary: #7A7A7D;
  --border-secondary: #7A7A7D;
  --border-third: #7A7A7D;
  --input-bg-default: #2F2F30;
  --input-bg-focused: #000000;
  --input-bg-filled: #2F2F30;
  --input-bg-error: #000000;
  --input-border-default: #7A7A7D;
  --input-border-focused: #E1E1E6;
  --input-border-filled: #7A7A7D;
  --input-border-error: #FF4B54;
}
