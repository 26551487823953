@font-face {
  font-family: 'Golos Text';
  src: url('../assets/fonts/GolosText-Regular.woff2') format('woff2'),
    url('../assets/fonts/GolosText-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Golos Text';
  src: url('../assets/fonts/GolosText-Medium.woff2') format('woff2'),
    url('../assets/fonts/GolosText-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Golos Text';
  src: url('../assets/fonts/GolosText-DemiBold.woff2') format('woff2'),
    url('../assets/fonts/GolosText-DemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Golos Text';
  src: url('../assets/fonts/GolosText-Bold.woff2') format('woff2'),
    url('../assets/fonts/GolosText-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Golos Text';
  src: url('../assets/fonts/GolosText-Black.woff2') format('woff2'),
    url('../assets/fonts/GolosText-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat Bold';
  src: url('../assets/fonts/montserrat-bold.woff2') format('woff2'),
    url('../assets/fonts/montserrat-bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

// Russo

@font-face {
  font-family: 'Russo One';
  src: url('../assets/fonts/russo-one.woff2') format('woff2'),
    url('../assets/fonts/russo-one.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// Roboto

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto-Regular.woff2') format('woff2'),
    url('../assets/fonts/Roboto-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto-Medium.woff2') format('woff2'),
    url('../assets/fonts/Roboto-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto-Bold.woff2') format('woff2'),
    url('../assets/fonts/Roboto-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto-Black.woff2') format('woff2'),
    url('../assets/fonts/Roboto-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

// pfdintextcondpro-xthinital-webfont

@font-face {
    font-family: 'pf_din_text_cond_pro';
    src: url('../assets/fonts/PFDinTextCondPro-Bold.woff2') format('woff2'),
         url('../assets/fonts/PFDinTextCondPro-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}
