@import 'src/scss/typography';
@import 'src/scss/mixins';

.container {
  @include center;
  position: relative;
  flex-direction: column;
  text-align: center;
  width: var(--mobile-container-width);
  max-width: 100%;
  height: 100%;
  z-index: 11;

  .close {
    position: absolute;
    top: 48px;
    right: 16px;
    cursor: pointer;

    svg {
      fill: var(--icon-secondary);
    }
  }

  .center {
    flex-direction: column;

    @include center;
    text-align: center;
  }

  .image {
    width: 48px;
    height: 48px;
    margin-bottom: 48px;
  }
  .title {
    @include title-2-medium;
    margin-bottom: 12px;
  }
  .text {
    @include headline-regular;
  }

  .btn {
    position: absolute;
    bottom: 16px;
    left: 16px;
    right: 16px;
  }
}
