@import 'src/scss/mixins';
@import 'src/scss/typography';

html {
  --popup-margin: 16px;
}

.container {
  z-index: 13;
  position: fixed;
  display: flex;
  flex-direction: row;
  align-self: center;
  padding: 12px 16px;
  background: var(--popup-background);
  color: var(--popup-content-title);
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  box-sizing: border-box;
  text-align: left;
  max-width: calc(var(--mobile-container-width) - 4 * var(--popup-margin));

  @include breakpoint(mobile) {
    max-width: calc(100% - 2 * var(--popup-margin));
  }

  &[data-theme='dark'] {
    --popup-background: var(--background-inversion);
    --popup-content-title: var(--text-default-inversion);
    --popup-content-text: var(--text-secondary-inversion);
  }

  &[data-theme='light'] {
    --popup-background: var(--background-inversion);
    --popup-content-title: var(--text-default-inversion);
    --popup-content-text: var(--text-secondary-inversion);
  }

  &[hidden] {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.15s linear, visibility 0.5s linear;
  }

  &[data-position-vertical='top'] {
    top: calc(env(safe-area-inset-top) + var(--popup-margin));
  }

  &[data-position-vertical='top48'] {
    top: calc(env(safe-area-inset-top) + var(--popup-margin) + 40px);
  }

  &[data-position-vertical='center'] {
    top: 50%;
    transform: translateY(-50%);
  }

  &[data-position-vertical='bottom'] {
    bottom: calc(var(--popup-margin) + 56px);
  }

  &[data-position-horizontal='center'] {
    left: 50%;
    transform: translateX(-50%);
  }

  &[data-position-horizontal='left'] {
    left: var(--popup-margin);
  }

  &[data-position-horizontal='right'] {
    right: var(--popup-margin);
  }

  &[data-align='left'] {
    text-align: left;
    justify-content: left;
  }

  &[data-align='center'] {
    text-align: center;
    justify-content: center;
  }

  &[data-align='right'] {
    text-align: right;
    justify-content: right;
  }

  &[data-width='fill'] {
    width: 100%;
  }

  .before {
    margin-right: var(--popup-margin);
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .title {
      @include headline-medium;
      color: var(--popup-content-title);
    }

    .text,
    .children {
      @include caption-1-regular;
      color: var(--popup-content-text);
    }
  }

  .btn {
    @include caption-1-medium;
    background-color: var(--button-bg-primary);
    padding: 2px 8px;
    border-radius: 24px;
    width: fit-content;
  }
}
