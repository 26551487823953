@import '../../../scss/mixins';
@import '../../../scss/typography';

.content {
  text-align: center;
  border-radius: 10px;
  padding: 12px;
  @include headline-medium;

  &[disabled] {
    background-color: var(--gray-light);
    border-color: var(--gray-light);
    color: var(--white);
    cursor: default;
  }

  &.fill {
    width: 100%;
  }
}

.xs {
  border-radius: 24px;
  padding: 2px 8px;
  @include caption-1-medium;
}

.primary {
  background-color: var(--button-bg-primary);
  border-color: var(--button-bg-primary);
  color: var(--button-label-primary);

  &:not([disabled]):hover {
    background-color: var(--button-bg-primary);
  }

  &[disabled] {
    background-color: var(--button-bg-disabled);
    border-color: var(--button-bg-disabled);
    color: var(--button-label-disabled);
  }

  svg path {
    stroke: var(--button-label-primary);
    fill: var(--button-label-primary);
  }
}

.disabled {
  background-color: var(--button-bg-disabled);
  border-color: var(--button-bg-disabled);
  color: var(--button-label-disabled);

  &:not([disabled]):hover {
    background-color: var(--button-bg-disabled);
  }
}

.secondary {
  background: var(--button-bg-secondary);
  border-color: var(--button-bg-secondary);
  color: var(--button-label-secondary);

  &:not([disabled]):hover {
    background: var(--button-bg-secondary);
    color: var(--button-label-secondary);
  }

  &:not([disabled]):active {
    background: var(--button-bg-secondary);
    color: var(--button-label-secondary);
  }
}

.light {
  background-color: var(--gray-20);
  color: var(--dark-primary);
  border-color: var(--gray-20);

  &:not([disabled]):hover {
    border-color: var(--dark-primary);
  }

  &:not([disabled]):active {
    background-color: var(--blue-light);
  }
}

.dark {
  background-color: var(--dark-primary);
  border-color: var(--dark-primary);
  color: var(--white);

  &:not([disabled]):hover {
    background-color: var(--gray-80);
  }
}

.blue {
  background-color: var(--blue-input);
  border-color: var(--blue-input);
  color: var(--blue-dark);

  &:not([disabled]):hover {
    background-color: var(--blue-light);
  }
}

.ghost {
  background-color: var(--button-bg-ghost);
  border-color: var(--button-bg-ghost);
  color: var(--button-label-ghost);

  svg {
    fill: var(--button-icon-ghost);
  }

  &:not([disabled]):hover {
    border-color: var(--dark-primary);
  }

  &:not([disabled]):active {
    background-color: var(--blue-light);
  }
}

.link {
  background-color: transparent;
  color: var(--text-secondary);

  &[disabled] {
    background-color: transparent;
    border-color: transparent;
    color: var(--text-secondary) !important;
    cursor: default;
  }
}

.inverse {
  background: var(--button-bg-inverse);
  border-color: var(--button-bg-inverse);
  color: var(--button-label-primary);

  &:not([disabled]):hover {
    background: var(--button-bg-inverse);
    color: var(--button-label-primary);
  }

  &:not([disabled]):active {
    background: var(--button-bg-inverse);
    color: var(--button-label-primary);
  }
}
