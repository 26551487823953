﻿html[data-scheme='nornickel'] {
  --scheme-version: 1.18;
  --brand-primary: #0077c8;
  --brand-primary-light: #008ded;
  --brand-secondary: #d17b2f;
  --brand-secondary-light: #ecad77;
  --yellow: #f5c714;
  --green: #69be28;
  --dark-red: #d23e4c;
  --red: #ff4b54;
  --light-violet: #e7f5ff;
  --orange: #ff7b51;
  --black: #000000;
  --gray90: #1e1e1f;
  --primary-dark: #2f2f30;
  --gray80: #666666;
  --gray50: #a3a3a6;
  --gray30: #e1e1e6;
  --gray20: #f0f0f2;
  --white: #ffffff;
  --brand-gradient-angle: 90;
  --brand-gradient-start-color: #c9dd03;
  --brand-gradient-end-color: #69be28;
  --avatar-gradient-angle: 45;
  --avatar-gradient-start-color: #0077c8;
  --avatar-gradient-end-color: #0077c8;
  --stable-primary-bg: #008ded;
  --stable-accent-bg: #0077c8;
  --stable-default-bg: #000000;
  --stable-secondary-bg: #2f2f30;
  --stable-white-bg: #ffffff;
  --stable-radius-xs: 4px;
  --stable-radius-s: 10px;
  --stable-radius-m: 16px;
  --stable-border-width: 1px;
  --stable-border-input-focus: 1.5px;
  --stable-divider-width: 0.5px;
  --stable-text-default: #ffffff;
  --stable-text-secondary: #e1e1e6;
  --stable-icon-default: #ffffff;
  --stable-icon-secondary: #e1e1e6;
  --stable-blocked: #a3a3a6;
  --stable-accent-light: #d17b2f;
  --stable-like: #ff4b54;
  --stable-player-seek-default: #666666;
  --stable-player-seek-loaded: #0077c8;
  --fonts-brand-header: var(--russo-one);
  --fonts-brand-header-weight: 400;
  --fonts-text: var(--roboto);
}

html[data-scheme='nornickel'][data-theme='light'] {
  --background-default: #ffffff;
  --background-overlay: #ffffff;
  --background-secondary: #f0f0f2;
  --background-inversion: #2f2f30;
  --background-accent: #e7f5ff;
  --support-error: #d23e4c;
  --support-success: #69be28;
  --support-warning: #f5c714;
  --icon-default: #2F2F30;
  --icon-secondary: #666666;
  --icon-inversion: #FFFFFF;
  --icon-accent1: #D17B2F;
  --icon-accent2: #0077C8;
  --divider-default: #F0F0F2;
  --button-bg-primary: #0077C8;
  --button-bg-secondary: #F0F0F2;
  --button-bg-ghost: #FFFFFF;
  --button-bg-disabled: #F0F0F2;
  --button-bg-inverse: #008DED;
  --button-label-primary: #FFFFFF;
  --button-label-secondary: #2F2F30;
  --button-label-ghost: #0077C8;
  --button-label-disabled: #666666;
  --button-icon-primary: #FFFFFF;
  --button-icon-secondary: #2F2F30;
  --button-icon-ghost: #0077C8;
  --button-icon-disabled: #666666;
  --button-icon-phone: #0077C8;
  --button-icon-email: #D17B2F;
  --text-default: #2f2f30;
  --text-secondary: #666666;
  --text-default-inversion: #ffffff;
  --text-secondary-inversion: #e1e1e6;
  --text-accent1: #D17B2F;
  --text-accent2: #0077C8;
  --text-accent3: #69BE28;
  --tab-indicator-active: #0077c8;
  --tab-label-active: #2f2f30;
  --tab-label-default: #666666;
  --bottom-nav-icon-default: #666666;
  --bottom-nav-icon-active: #0077C8;
  --bottom-nav-badge: #D23E4C;
  --chips-border-default: #e1e1e6;
  --chips-border-active: #0077c8;
  --chips-label-default: #2f2f30;
  --chips-label-active: #ffffff;
  --chips-bg-default: #ffffff;
  --chips-bg-active: #0077c8;
  --border-primary: #ffffff;
  --border-secondary: #e1e1e6;
  --border-third: #2f2f30;
  --input-bg-default: #f0f0f2;
  --input-bg-focused: #ffffff;
  --input-bg-filled: #f0f0f2;
  --input-bg-error: #ffffff;
  --input-border-default: #F0F0F2;
  --input-border-focused: #0077C8;
  --input-border-filled: #F0F0F2;
  --input-border-error: #D23E4C;
}

html[data-scheme='nornickel'][data-theme='dark'] {
  --background-default: #000000;
  --background-overlay: #1E1E1F;
  --background-secondary: #2F2F30;
  --background-inversion: #FFFFFF;
  --background-accent: #2F2F30;
  --text-default: #F0F0F2;
  --text-secondary: #A3A3A6;
  --text-default-inversion: #2F2F30;
  --text-secondary-inversion: #666666;
  --text-accent1: #ECAD77;
  --text-accent2: #008DED;
  --text-accent3: #69BE28;
  --icon-default: #F0F0F2;
  --icon-secondary: #A3A3A6;
  --icon-inversion: #2F2F30;
  --icon-accent1: #ECAD77;
  --icon-accent2: #008DED;
  --divider-default: #666666;
  --button-bg-primary: #008DED;
  --button-bg-secondary: #2F2F30;
  --button-bg-ghost: #000000;
  --button-bg-disabled: #2F2F30;
  --button-bg-inverse: #0077C8;
  --button-label-primary: #FFFFFF;
  --button-label-secondary: #FFFFFF;
  --button-label-ghost: #008DED;
  --button-label-disabled: #666666;
  --button-icon-primary: #FFFFFF;
  --button-icon-secondary: #FFFFFF;
  --button-icon-ghost: #008DED;
  --button-icon-disabled: #666666;
  --button-icon-phone: #008DED;
  --button-icon-email: #ECAD77;
  --tab-indicator-active: #008DED;
  --tab-label-active: #F0F0F2;
  --tab-label-default: #A3A3A6;
  --bottom-nav-icon-default: #A3A3A6;
  --bottom-nav-icon-active: #008DED;
  --bottom-nav-badge: #D23E4C;
  --chips-bg-default: #000000;
  --chips-bg-active: #008DED;
  --chips-label-default: #F0F0F2;
  --chips-label-active: #FFFFFF;
  --chips-border-default: #666666;
  --chips-border-active: #008DED;
  --support-error: #FF4B54;
  --support-success: #69BE28;
  --support-warning: #F5C714;
  --border-primary: #666666;
  --border-secondary: #666666;
  --border-third: #666666;
  --input-bg-default: #2F2F30;
  --input-bg-focused: #000000;
  --input-bg-filled: #2F2F30;
  --input-bg-error: #000000;
  --input-border-default: #666666;
  --input-border-focused: #008DED;
  --input-border-filled: #666666;
  --input-border-error: #FF4B54;
}
